import React from 'react';
import Brand from '../../components/brand/Brand';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';

function Refund({ title }) {
  return (
    <div>
      <Header />
      <div className='page-content'>
        <Breadcrum title={'Refund Policy'} />
        <section className='section-padding'>
          <div className='container'>
            <div className='row g-4'>
              <div className='col-12 col-xl-12'>
                <h3 className='fw-bold'>Refund Policy</h3>
                <ol>
                  <li>
                    Send us an Exchange request on orders@Dazzora.in with the
                    reason for the same, and the subject of the email ‘Exchange
                    Request, #Order number’
                  </li>
                  <li>
                    The Exchange request for items purchased must be filed
                    within 48 hours of receiving them.
                  </li>
                  <li>
                    Items to be exchanged must be returned within 10 days of the
                    date of receipt.
                  </li>
                  <li>
                    Items to be exchanged are at the customer’s expense. Dazzora
                    will arrange the pickup and the exchanged item delivered
                    will include the pickup and new delivery cost as well.
                  </li>
                  <li>
                    Items to be exchanged should not have any signs of wear and
                    tear or of having ever been worn outdoors by the customer.
                    The Brand Tag should stay intact.
                  </li>
                  <li>
                    An email/message will be sent to the customer as soon as the
                    returned products reach us and the exchange item will be
                    shipped.
                  </li>
                  <li>
                    Dazzora reserves the right to decline the exchange request
                    if the reasons mentioned in the request are found to be
                    inadequate or if the product exchange does not comply with
                    the terms and conditions stated above.
                  </li>
                  <li>Item/Order cannot be exchanged more than once.</li>
                  <li>Sale items do not qualify for Exchange.</li>
                  <li>Dazzora does not offer any Returns.</li>
                </ol>
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Refund;
