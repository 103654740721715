import React, { useState, useEffect } from 'react';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';
import ProductTitle from '../../components/product/ProductTitle';
import {
  useSingleProduct,
  useSingleProductBySlug,
} from '../../shared/hooks/UseProduct';
import { useHistory, useLocation } from 'react-router';
import * as qs from 'qs';
import {
  useAddToWishlist,
  useCheckInWishlist,
} from '../../shared/hooks/UseWishlist';
import {
  useAddToCart,
  useCheckInCart,
  useDecreaseQuantity,
} from '../../shared/hooks/UseCart';
import ProductPrice from '../../components/product/ProductPrice';
import DiscountSection from '../../components/product/DiscountSection';
import ProductOptions from '../../components/product/ProductOptions';
import renderHTML from 'react-render-html';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { TextArea, TextInput } from '../../components/Form/Form';
import Rating from 'react-rating';
// import { addReview, getAllReviews } from "../../store/actions/review_action";
import {
  useCreateReview,
  useSelectAllReviews,
} from '../../shared/hooks/UseReview';
import moment from 'moment';
import SingleProductImage from '../../components/product/SingleProductImage';
import SingleProductGallery from '../../components/product/SingleProductGallery';
import CartComponent from '../cart/CartComponent';
import ReviewSection from '../../components/product/ReviewSection';

function SingleProduct({ match }) {
  // console.log("PRODUCT", product);
  const history = useHistory();
  const location = useLocation();
  const [reviewData, addReview] = useCreateReview();
  const [cart, addData] = useAddToCart();

  const [data] = useSingleProductBySlug(match.params.slug);
  const { product, product_loading } = data;
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  // const [activeVariation, setActiveVariation] = useState(null);
  const [activeMedia, setActiveMedia] = useState(null);
  const [activeVariantVersion, setActiveVariantVersion] = useState(null);
  const [wishlist, addToWishlist] = useAddToWishlist();
  const [productInWishlist] = useCheckInWishlist(product);
  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [activeVariation, setActiveVariation] = useState(
    queryParams.variation ? queryParams.variation : null
  );

  const addToCart = async (product) => {
    if (!product.is_variable_product) {
      await addData({
        name: product.name,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: product.slug,
        image: product.media && product.media[0],
        product: product._id,
      });
    } else {
      let title = '';
      activeVariantVersion &&
        activeVariantVersion.options &&
        activeVariantVersion.options.map((item) => {
          title = title + ' ' + item.value;
        });
      await addData({
        name: `${product.name} ${title}`,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: `${product.slug}?variation=${activeVariantVersion._id}`,
        image: product.media && product.media[0],
        product: product._id,
      });
    }
  };

  useEffect(() => {
    // console.warn("Variations", product);
    if (product && product.variations) {
      if (!product.is_variable_product || !product.variations) {
      } else {
        if (activeVariation) {
          let filteredOptions = product.variations.filter(
            (item) => item._id == activeVariation
          );
          if (filteredOptions.length > 0) {
            setActiveVariation(filteredOptions[0]._id);
            setActiveVariantVersion(filteredOptions[0]);
          }
        } else {
          // setActiveVariation(product.variations[0]._id);
          // setActiveVariantVersion(product.variations[0]);
        }
      }
    }
  }, [product, activeVariation]);
  useEffect(() => {
    if (product) {
      setActiveVariation(queryParams.variation);
    }
  }, [queryParams.variation]);

  useEffect(() => {
    if (activeVariation && product && product.variations) {
      let selectedVariant = product.variations.filter(
        (item) => item._id == activeVariation
      );
      if (selectedVariant.length > 0) {
        setActiveVariantVersion(selectedVariant[0]);
        if (selectedVariant[0].media && selectedVariant[0].media.length > 0) {
          setActiveMedia(selectedVariant[0].media);
        } else {
          setActiveMedia(product.media);
        }
      }
    }
  }, [activeVariation, product]);

  const isEqual = (value, other) => {
    var type = Object.prototype.toString.call(value);

    if (type !== Object.prototype.toString.call(other)) return false;

    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    var valueLen =
      type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen =
      type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    var compare = function (item1, item2) {
      var itemType = Object.prototype.toString.call(item1);

      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!isEqual(item1, item2)) return false;
      } else {
        if (itemType !== Object.prototype.toString.call(item2)) return false;

        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }
      }
    };

    if (type === '[object Array]') {
      for (var i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }

    return true;
  };
  const checkExistence = ({ array, label, value }) => {
    if (array && label && value) {
      const filterData = array.options.filter(
        (item) => item.label == label && value == item.value
      );
      if (filterData && filterData.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleVariationChange = ({ label, value }) => {
    if (
      product.variations &&
      product.variations.length > 0 &&
      activeVariantVersion &&
      activeVariantVersion.options
    ) {
      const newVariationToMap = activeVariantVersion.options.map((item) => {
        if (item.label == label) {
          return {
            label: label,
            value: value,
          };
        } else {
          return {
            label: item.label,
            value: item.value,
          };
        }
      });
      // console.log("NEW VARIATION", newVariationToMap);
      const newVariationProductsMap = product.variations.map((item) => {
        let newAllOptions = item.options.map((newItem) => {
          return {
            label: newItem.label,
            value: newItem.value,
          };
        });
        item.options = newAllOptions;
        return item;
      });
      console.log('NEW VARIATION PRODUCTS', newVariationProductsMap);

      let finalFilterProductVariation = newVariationProductsMap.filter((item) =>
        isEqual(item.options, newVariationToMap)
      );
      // console.log("FInal Product Variation", finalFilterProductVariation);
      if (
        finalFilterProductVariation &&
        finalFilterProductVariation.length > 0
      ) {
        let queryParams = { variation: finalFilterProductVariation[0]._id };
        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true,
        });
        setActiveVariation(finalFilterProductVariation[0]._id);
        setActiveVariantVersion(product.variations[0]);
        history.push(`${location.pathname}?${query}`);
      }
    } else {
      if (product.variations && product.variations.length > 0) {
        console.log(label, value, product);
        const newVariationProductsMap = product.variations.map((item) => {
          let newAllOptions = item.options.map((newItem) => {
            return {
              label: newItem.label,
              value: newItem.value,
            };
          });
          item.options = newAllOptions;
          return item;
        });

        let finalFilterProductVariation = newVariationProductsMap.filter(
          (item) =>
            item.options &&
            item.options[0] &&
            item.options[0].label == label &&
            item.options[0].value == value
        );
        console.log('finalFilterProductVariation', finalFilterProductVariation);

        let queryParams = { variation: finalFilterProductVariation[0]._id };
        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true,
        });
        history.push(`${location.pathname}?${query}`);
      }
    }
  };

  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    if (product && product.reviews) {
      const mapData = product.reviews.map((item) => Number(item.ratings));
      const averageRatingData =
        mapData.reduce((a, b) => a + b, 0) / product.reviews.length;
      setAverageRating(averageRatingData);
    }
  }, [product]);

  return (
    <div>
      <Header />
      <div className='page-content single-product'>
        <Breadcrum title={product && product.name} />
        {product && (
          <section className='py-4'>
            <div className='container'>
              <div className='row g-4'>
                <div className='col-12 col-xl-7'>
                  <div className='product-images'>
                    <div className='product-zoom-images'>
                      <div className='row row-cols-2 g-3'>
                        <SingleProductGallery
                          product={product}
                          activeVariantVersion={activeVariantVersion}
                        />
                      </div>
                      {/*end row*/}
                    </div>
                  </div>
                </div>
                <div className='col-12 col-xl-5'>
                  <div className='product-info'>
                    <h4 className='product-title fw-bold mb-1'>
                      <ProductTitle product={product} />
                    </h4>

                    {/* <p className="mb-0">
                      Women Pink &amp; Off-White Printed Kurta with Palazzos
                    </p> */}
                    <hr />
                    <ReviewSection product={product} />
                    <hr />
                    <div className='product-price d-flex align-items-center gap-3'>
                      <div className='h4 fw-bold'>
                        <ProductPrice
                          product={product}
                          activeVariantVersion={activeVariantVersion}
                          currency={'₹'}
                        />
                      </div>

                      <div className='h4 fw-bold text-danger'>
                        <DiscountSection
                          product={product}
                          activeVariantVersion={activeVariantVersion}
                          cssName='discount-single'
                        />
                      </div>
                    </div>
                    <p className='fw-bold mb-0 mt-1 text-success'>
                      inclusive of all taxes
                    </p>
                    <div className='more-colors mt-4'>
                      <h6 className='fw-bold mb-3'>Select Size</h6>
                      <div className='d-flex align-items-center gap-2 flex-wrap'>
                        {product.is_variable_product && (
                          <div className='product-options'>
                            <ProductOptions
                              product={product}
                              activeVariantVersion={activeVariantVersion}
                              handleVariationChange={handleVariationChange}
                              checkExistence={checkExistence}
                              hideOptionTitle={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='size-chart mt-4'>
                      {
                        // <h6 className="fw-bold mb-3">Select Size</h6>
                        // <div className="d-flex align-items-center gap-2 flex-wrap">
                        //   <div className>
                        //     <button type="button">XS</button>
                        //   </div>
                        //   <div className>
                        //     <button type="button">S</button>
                        //   </div>
                        //   <div className>
                        //     <button type="button">M</button>
                        //   </div>
                        //   <div className>
                        //     <button type="button">L</button>
                        //   </div>
                        //   <div className>
                        //     <button type="button">XL</button>
                        //   </div>
                        //   <div className>
                        //     <button type="button">XXL</button>
                        //   </div>
                        // </div>
                      }
                    </div>
                    <div className='cart-buttons mt-3'>
                      <div className=''>
                        <CartComponent
                          product={product}
                          activeVariantVersion={activeVariantVersion}
                        />
                        {/* <a
                          onClick={() => addToCart(product)}
                          href="javascript:;"
                          className="btn btn-lg btn-dark btn-ecomm px-5 py-3 col-lg-6"
                        >
                          <i className="bi bi-basket2 me-2" />
                          Add to Bag
                        </a>
                        <a
                          onClick={() => addToWishlist(product)}
                          href="javascript:;"
                          className="btn btn-lg btn-outline-dark btn-ecomm px-5 py-3"
                        >
                          <i className="bi bi-suit-heart me-2" />
                          Wishlist
                        </a> */}
                      </div>
                    </div>
                    <hr className='my-3' />
                    <div className='product-info'>
                      <h6 className='fw-bold mb-3'>Product Details</h6>
                      <p className='mb-1'>
                        {product.description && renderHTML(product.description)}
                      </p>
                    </div>
                    <hr className='my-3' />
                    <div className='customer-ratings'>
                      <h6 className='fw-bold mb-3'>Customer Ratings </h6>
                      <div className='d-flex align-items-center gap-4 gap-lg-5 flex-wrap flex-lg-nowrap'>
                        <div className>
                          <h1 className='mb-2 fw-bold'>
                            {averageRating ? (
                              <>
                                {averageRating}
                                <span className='fs-5 ms-2 text-warning'>
                                  <i className='bi bi-star-fill' />
                                </span>
                              </>
                            ) : (
                              <span className='fs-5 ms-2 text-warning'>
                                No Reviews Yet
                              </span>
                            )}
                          </h1>
                          {product.reviews && product.reviews.length > 0 && (
                            <p className='mb-0'>
                              {product.reviews.length} Verified Buyers
                            </p>
                          )}
                        </div>
                        <div className='vr d-none d-lg-block' />
                        {/* <div className="w-100">
                          <div className="rating-wrrap hstack gap-2 align-items-center">
                            <p className="mb-0">5</p>
                            <div className>
                              <i className="bi bi-star" />
                            </div>
                            <div
                              className="progress flex-grow-1 mb-0 rounded-0"
                              style={{ height: 4 }}
                            >
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: "75%" }}
                              />
                            </div>
                            <p className="mb-0">1528</p>
                          </div>
                          <div className="rating-wrrap hstack gap-2 align-items-center">
                            <p className="mb-0">4</p>
                            <div className>
                              <i className="bi bi-star" />
                            </div>
                            <div
                              className="progress flex-grow-1 mb-0 rounded-0"
                              style={{ height: 4 }}
                            >
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: "65%" }}
                              />
                            </div>
                            <p className="mb-0">253</p>
                          </div>
                          <div className="rating-wrrap hstack gap-2 align-items-center">
                            <p className="mb-0">3</p>
                            <div className>
                              <i className="bi bi-star" />
                            </div>
                            <div
                              className="progress flex-grow-1 mb-0 rounded-0"
                              style={{ height: 4 }}
                            >
                              <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                style={{ width: "45%" }}
                              />
                            </div>
                            <p className="mb-0">258</p>
                          </div>
                          <div className="rating-wrrap hstack gap-2 align-items-center">
                            <p className="mb-0">2</p>
                            <div className>
                              <i className="bi bi-star" />
                            </div>
                            <div
                              className="progress flex-grow-1 mb-0 rounded-0"
                              style={{ height: 4 }}
                            >
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "35%" }}
                              />
                            </div>
                            <p className="mb-0">78</p>
                          </div>
                          <div className="rating-wrrap hstack gap-2 align-items-center">
                            <p className="mb-0">1</p>
                            <div className>
                              <i className="bi bi-star" />
                            </div>
                            <div
                              className="progress flex-grow-1 mb-0 rounded-0"
                              style={{ height: 4 }}
                            >
                              <div
                                className="progress-bar bg-danger"
                                role="progressbar"
                                style={{ width: "25%" }}
                              />
                            </div>
                            <p className="mb-0">27</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <hr className='my-3' />
                    <div className='customer-reviews'>
                      <h6 className='fw-bold mb-3'>
                        Customer Reviews (
                        {product.reviews && product.reviews.length > 0
                          ? `${product.reviews.length} Reviews`
                          : 'No Reviews Yet'}
                        )
                      </h6>

                      <div className='reviews-wrapper'>
                        {product.reviews &&
                          product.reviews.map((item) => {
                            return (
                              <>
                                <div className='d-flex flex-column flex-lg-row gap-3'>
                                  <div className>
                                    <span className='badge bg-green rounded-0'>
                                      {item.ratings}
                                      <i className='bi bi-star-fill ms-1' />
                                    </span>
                                  </div>
                                  <div className='flex-grow-1'>
                                    <p className='mb-2'>{item.message}</p>

                                    <div className='d-flex flex-column flex-sm-row gap-3 mt-3'>
                                      <div className='hstack flex-grow-1 gap-3'>
                                        <p className='mb-0'>{item.name}</p>
                                        <div className='vr' />
                                        <div className='date-posted'>
                                          {moment(item.createdAt).format(
                                            'DD/MM/YYYY'
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end row*/}
            </div>
          </section>
        )}
        <section>
          <div className='product-details-content-tab-section section-inner-bg section-top-gap-100'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div
                    className='product-details-content-tab-wrapper'
                    data-aos='fade-up'
                    data-aos-delay={0}
                  >
                    {/* Start Product Details Tab Button */}
                    <ul className='nav tablist product-details-content-tab-btn d-flex justify-content-center'>
                      {/* <li>
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#specification"
              >
                <h5>Specification</h5>
              </a>
            </li> */}
                      <li>
                        <a
                          className='nav-link'
                          data-bs-toggle='tab'
                          href='#review'
                        >
                          <h5>Write A Review!</h5>
                        </a>
                      </li>
                    </ul>
                    {/* End Product Details Tab Button */}
                    {/* Start Product Details Tab Content */}
                    <div className='product-details-content-tab'>
                      <div className='single-tab-content-item'>
                        {/* Start - Review Comment */}

                        {/* End - Review Comment */}
                        <div className='review-form'>
                          <div className='review-form-text-top'>
                            <h5>ADD A REVIEW</h5>
                          </div>
                          <Formik
                            initialValues={{
                              name: '',
                              email: '',
                              message: '',
                              ratings: '',
                            }}
                            validationSchema={Yup.object({
                              name: Yup.string().required('Required'),
                              email: Yup.string().required('Required'),
                              message: Yup.string().required('Required'),
                              ratings: Yup.string().required('Required'),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              setSubmitting(true);
                              values.product = product._id;
                              console.log('REVIEW', values);
                              await addReview(values);
                              resetForm();
                              setSubmitting(false);
                            }}
                          >
                            {(formik) => {
                              console.log(formik);
                              return (
                                <Form>
                                  <div className='row'>
                                    <div className='col-md-6'>
                                      <div className='default-form-box mb-20'>
                                        <TextInput
                                          label=' Your name '
                                          name='name'
                                          type='text'
                                          placeholder='Enter your name'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-md-6'>
                                      <div className='default-form-box mb-20'>
                                        <TextInput
                                          label=' Your Email *'
                                          name='email'
                                          type='email'
                                          placeholder='Enter your email'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-md-12 rat'>
                                      <Rating
                                        initialRating={formik.values.ratings}
                                        emptySymbol='fa fa-star-o fa-2x full mr-3'
                                        fullSymbol='fa fa-star fa-2x full mr-3'
                                        fractions={2}
                                        onChange={(value) => {
                                          formik.setFieldValue(
                                            'ratings',
                                            value
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className='col-12'>
                                      <div className='default-form-box mb-20'>
                                        <label htmlFor='comment-review-text'>
                                          Your review <span>*</span>
                                        </label>
                                        <TextArea
                                          placeholder='Write a review'
                                          name='message'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-12'>
                                      <button
                                        className='form-submit-btn'
                                        type='submit'
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    {/* End Product Details Tab Content */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default SingleProduct;
