import React from 'react';
import Brand from '../../components/brand/Brand';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';

function About({ title }) {
  return (
    <div>
      <Header />
      <div className='page-content'>
        <Breadcrum title={'About us'} />
        <section className='section-padding'>
          <div className='container'>
            <div className='row g-4'>
              <div className='col-12 col-xl-6'>
                <h3 className='fw-bold'>About us -</h3>
                <p>
                  Dazzora is an online e-commerce website that offers a wide
                  range of products, from fashion and beauty to electronics and
                  home decor. At Dazzora, we pride ourselves on providing our
                  customers with high-quality products at affordable prices,
                  delivered right to their doorstep.
                </p>
                <p>
                  Our mission is to create a seamless and enjoyable shopping
                  experience for our customers, with easy-to-navigate website
                  features and a user-friendly interface. We believe that
                  everyone deserves to feel confident and beautiful, which is
                  why we offer a diverse range of products that cater to all
                  tastes and preferences.
                </p>
                <p>
                  Our team at Dazzora is committed to delivering exceptional
                  customer service and ensuring that all of our customers are
                  satisfied with their purchases. We work tirelessly to ensure
                  that our products are sourced from reliable suppliers, and
                  that our prices are competitive in the market.
                </p>
              </div>
              <div className='col-12 col-xl-6'>
                <img
                  src='https://images.pexels.com/photos/7679877/pexels-photo-7679877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                  className='img-fluid'
                  alt
                />
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
        {/*   <Brand /> */}
        <Footer />
      </div>
    </div>
  );
}

export default About;
