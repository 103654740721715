import React from 'react';
import Brand from '../../components/brand/Brand';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';

function Privacy({ title }) {
  return (
    <div>
      <Header />
      <div className='page-content'>
        <Breadcrum title={'Privacy Policy'} />
        <section className='section-padding'>
          <div className='container'>
            <div className='row g-4'>
              <div className='col-12 col-xl-12'>
                <h3 className='fw-bold'>Privacy Policy</h3>
                <div>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      This Privacy Policy covers the information Dazzora
                      ("Company" and/or "We") collects from the user(s)
                      ("User(s)" and/or "You") of www.dazzora.com ("Website")
                      This Privacy Policy should be read in conjunction and
                      together with the User Agreement of the Website. Personal
                      Information of a User(s) is collected if the User(s)
                      registers with the Website, accesses the Website or take
                      any action on the Website Information Collection and Use.
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The Personal Information which You may provide to us
                      and/or which we may collect is or could be the following:
                    </span>
                  </p>
                  <ul>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your registration details which may include the password
                        provided by You. You may note that We adopt reasonable
                        security measures to protect Your password from being
                        exposed or disclosed to anyone including the Company.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your shipping, billing, tax registration, and other
                        information pertaining to Your sale or purchase
                        transaction on the Website.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your transaction details with the other users of the
                        Website.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your usage behaviour of the Website.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Details of the computer system or computer network which
                        You use to visit the Website and undertake any activity
                        on the Website
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Our primary purposes in collecting information from You
                      are to allow You to use the Website and various features
                      and services offered by the Company on or in relation to
                      the Website; contact you for any services provided by the
                      Company or its affiliates or its business partners and
                      advertisers; to record Your information and details as
                      permitted and required under applicable laws, statutory
                      direction or judiciary orders; to serve various promotion
                      materials and advertising materials to you; and such other
                      uses as provided in the User Agreement and this Privacy
                      Policy. We may also use the information for transactional
                      emails or to provide You with information, direct
                      marketing, online and offline advertising and other
                      materials regarding products, services, and other offers
                      from time to time in connection with the Company or its
                      parent, subsidiary, and affiliated companies (“Company
                      Entities”) and its joint ventures.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We may also collect information to track User behaviour
                      and preferences for internal analytics from all Users of
                      the Website. This information is collected using server
                      log files and tracking technologies to collect and analyse
                      certain types of technical information and may include
                      cookies and web beacons.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We may combine your Personal Information, other
                      information and information collected from tracking
                      technologies and aggregate it with information collected
                      from other Users using our website to attempt to provide
                      You with a better experience on our website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      You understand, agree and acknowledge that our collection,
                      storage and processing of your Personal Information is for
                      a lawful purpose connected with a function or activity of
                      the Company Entities and its joint ventures. You further
                      understand, agree, and acknowledge that your Personal
                      Information which is classified as sensitive personal
                      information as per applicable laws is considered necessary
                      for the Company to provide various services on its Website
                      to You and for Your usage of the Website and other
                      services provided by Company in relation to the Website.
                      Information Sharing and Disclosure
                    </span>
                  </p>
                  <p>
                    <strong>
                      You understand, agree, and acknowledge that Company uses
                      third party services providers to Privacy Policy
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      This Privacy Policy covers the information Dazzora
                      ("Company" and/or "We") collects from the user(s)
                      ("User(s)" and/or "You") of www.dazzora.com ("Website")
                      This Privacy Policy should be read in conjunction and
                      together with the User Agreement of the Website. Personal
                      Information of a User(s) is collected if the User(s)
                      registers with the Website, accesses the Website or take
                      any action on the Website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>INFORMATION COLLECTION AND USE</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The personal information which You may provide to us
                      and/or which we may collect is or could be the following:
                    </span>
                  </p>
                  <ul>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your registration details which may include the password
                        provided by You. You may note that We adopt reasonable
                        security measures to protect Your password from being
                        exposed or disclosed to anyone including the Company.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your shipping, billing, tax registration, and other
                        information pertaining to Your sale or purchase
                        transaction on the Website.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your transaction details with the other users of the
                        Website.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Your usage behaviour of the Website.
                      </span>
                    </li>
                    <li
                      style={{
                        fontWeight: 400,
                        listStyleType: 'disc',
                        marginLeft: 10,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Details of the computer system or computer network which
                        You use to visit the Website and undertake any activity
                        on the Website
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Our primary purposes in collecting information from You
                      are to allow You to use the Website and various features
                      and services offered by the Company on or in relation to
                      the Website; contact you for any services provided by the
                      Company or its affiliates or its business partners and
                      advertisers; to record Your information and details as
                      permitted and required under applicable laws, statutory
                      direction or judiciary orders; to serve various promotion
                      materials and advertising materials to you; and such other
                      uses as provided in the User Agreement and this Privacy
                      Policy. We may also use the information for transactional
                      emails or to provide You with information, direct
                      marketing, online and offline advertising and other
                      materials regarding products, services and other offers
                      from time to time in connection with the Company or its
                      parent, subsidiary and affiliated companies (“Company
                      Entities”) and its joint ventures.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We may also collect information to track User behaviour
                      and preferences for internal analytics from all Users of
                      the Website. This information is collected through the use
                      of server log files and tracking technologies to collect
                      and analyse certain types of technical information and may
                      include cookies and web beacons.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We may combine your Personal Information, other
                      information and information collected from tracking
                      technologies and aggregate it with information collected
                      from other Users using our website to attempt to provide
                      You with a better experience on our website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      You understand, agree, and acknowledge that our
                      collection, storage and processing of your Personal
                      Information is for a lawful purpose connected with a
                      function or activity of the Company Entities and its joint
                      ventures. You further understand, agree, and acknowledge
                      that your Personal Information which is classified as
                      sensitive personal information as per applicable laws is
                      considered necessary for the Company to provide various
                      services on its Website to You and for Your usage of the
                      Website and other services provided by Company in relation
                      to the Website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>INFORMATION SHARING AND DISCLOSURE</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company is the recipient of all the Personal Information
                      and shall exercise reasonable commercial endeavours for
                      the prevention of the Personal Information provided by the
                      Users. We may enable access of the Users’ information to
                      the Company Entities, joint ventures, agents or third
                      parties for the purposes of the services provided by them
                      or for any other marketing related activity undertaken by
                      or on behalf of the Company Entities and/or its joint
                      ventures. We will ensure on reasonable commercial efforts
                      basis that the third parties and agents employed by us for
                      the purposes of the Website are under an obligation to
                      maintain confidentiality with respect to the Personal
                      Information provided by the Users and to use it strictly
                      for the purposes of the Website only.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company may disclose your Personal Information to such
                      extent as the Company may deem necessary for you to enter
                      into commercial transactions with other users of the
                      Website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>INFORMATION SHARING AND DISCLOSURE</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company is the recipient of all the Personal Information
                      and shall exercise reasonable commercial endeavours for
                      the prevention of the Personal Information provided by the
                      Users. We may enable access of the Users’ information to
                      the Company Entities, joint ventures, agents or third
                      parties for the purposes of the services provided by them
                      or for any other marketing related activity undertaken by
                      or on behalf of the Company Entities and/or its joint
                      ventures. We will ensure on reasonable commercial efforts
                      basis that the third parties and agents employed by us for
                      the purposes of the Website are under an obligation to
                      maintain confidentiality with respect to the Personal
                      Information provided by the Users and to use it strictly
                      for the purposes of the Website only.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company may disclose your Personal Information to such
                      extent as the Company may deem necessary for you to enter
                      into commercial transactions with other users of the
                      Website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>COMPLIANCE WITH LAWS AND LAW ENFORCEMENT</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company cooperates with mandated government and law
                      enforcement agencies or to any third parties by an order
                      under law for the time being in force to enforce and
                      comply with the law. We will disclose any information
                      about You to government or law enforcement officials or
                      private parties as we, in our sole discretion, believe
                      necessary or appropriate to respond to claims and legal
                      process, to protect the property and rights of Company or
                      a third party, to protect the safety of the public or any
                      person, or to prevent or stop any illegal, unethical, or
                      legally actionable activity. Company may also provide your
                      Personal Information to various tax authorities upon any
                      demand or request from them.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>BUSINESS TRANSFERS</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company may sell, transfer or otherwise share some or all
                      of its assets, including your Personal Information, in
                      connection with a merger, acquisition, reorganization or
                      sale of assets or in the event of bankruptcy. Should such
                      a sale or transfer occur, we will ensure that the Personal
                      Information You have provided through the Website is
                      stored and used by the transferee in a manner that is
                      consistent with this Privacy Policy.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>EMAIL POLICIES</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company may use your Personal Information for the purposes
                      of the Website. You have full control regarding which of
                      these emails You want to receive. If You decide at any
                      time that You no longer wish to receive such
                      communications from us, please follow the unsubscribe
                      instructions provided in any of the communications. Please
                      note that once we receive your request, it may take an
                      additional period of time for you opt-out to become
                      effective.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>DELETING YOUR INFORMATION</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If You wish to have the Personal Information that You have
                      provided to us deleted, You can always do so by sending a
                      request to us on the e-mail id of our Customer Services
                      department provided at the bottom of this page. You may
                      note that deletion of certain Personal Information may
                      lead to cancellation of your registration with the Website
                      and your access to certain features of the Website.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>SECURITY</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Company uses ordinary industry standard technology
                      designed to help keep your Personal Information safe. The
                      secure server software (SSL) encrypts all information You
                      put in before it is sent to us. Furthermore, all of the
                      customer data we collect is protected against unauthorized
                      access. To prevent unauthorized access, maintain data
                      accuracy, and ensure correct use of information, We will
                      employ commercially reasonable and practicable security
                      practices and procedures and security methods and
                      technologies. We will also ensure on reasonable commercial
                      efforts basis that any agent or third party that we employ
                      complies with the same security standards as us for the
                      protection of your Personal Information.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Your information may be transferred to or be maintained on
                      computers, computer systems and computer resources located
                      outside of your state or country where the privacy laws
                      may not be as protective as those where you live. If You
                      are located outside India and choose to provide
                      information to us, please be aware that Company keeps or
                      transfers information to India and processes it there.
                      Your submission of such information represents your
                      agreement to that transfer.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Unfortunately, the transmission of information over the
                      Internet is not completely secure. Although We strive to
                      protect your personal data, We cannot guarantee the
                      security of your data while it is being transmitted to our
                      site; any transmission is at your own risk. Once We have
                      received your information, We have commercially reasonable
                      procedures and security features in place to reasonably
                      endeavour to prevent unauthorized access.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>CHANGES IN PRIVACY POLICY</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      From time to time We may update this Privacy Policy. Your
                      continued subscription to our Services constitutes an
                      acceptance of the then-current Privacy Policy and Terms of
                      Use so We encourage You to visit this page periodically to
                      review any changes.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>PHISHING</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Identity theft and the practice currently known as
                      "phishing" are of great concern to Company. Safeguarding
                      information to help protect You from identity theft is a
                      top priority. We do not and will not, at any time, request
                      your credit card information or national identification
                      numbers in a non-secure or unsolicited e-mail or telephone
                      communication.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      <br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <strong>CONTACT US&nbsp;</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If You have any questions about this Privacy Policy, the
                      practices of Company or your dealings with the Website,
                      You can contact us via the links at the bottom of the
                      page.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Privacy;
